
import { defineComponent } from "vue";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";

export default defineComponent({
  name: "Notification",
  props: {},
  components: {},
  setup() {
    const {
      notificationText,
      notificationState,
      notificationType,
      closeNotification,
    } = useNotificationControl();

    return {
      notificationText,
      notificationState,
      notificationType,
      closeNotification,
    };
  },
});
