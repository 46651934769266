import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

type NotificationType = "success" | "error";

type NotiFicationControl = (config?: typeof notificationConfig) => {
  notificationState: Ref<boolean>;
  notificationText: Ref<string>;
  notificationType: Ref<NotificationType>;
  openNotification: (arg?: { type?: NotificationType; text?: string }) => void;
  closeNotification: () => void;
};

const notificationState = ref(false);
const notificationText = ref("");
const notificationType = ref<NotificationType>("success");

const notificationConfig = {
  fadeOutTime: 5000,
  defaultType: "success" as NotificationType,
};

const useNotificationControl: NotiFicationControl = (
  config = notificationConfig
) => {
  const { t } = useI18n();

  const getDefaultText = (type: NotificationType) => {
    if (type == "success") return t("common.updateSuccess");
    else return t("common.error");
  };

  const openNotification = (
    option: { type?: NotificationType; text?: string } = {
      type: config.defaultType,
      text: "",
    }
  ) => {
    if (option.type) notificationType.value = option.type;
    else notificationType.value = config.defaultType;

    if (option.text) notificationText.value = option.text;
    else notificationText.value = getDefaultText(notificationType.value);

    notificationState.value = true;
    setTimeout(() => {
      notificationState.value = false;
    }, config.fadeOutTime);
  };

  const closeNotification = () => {
    notificationState.value = false;
  };

  return {
    notificationState,
    notificationText,
    notificationType,
    openNotification,
    closeNotification,
  };
};

export default useNotificationControl;
