import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a14d6c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabTags, (tab, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(["nav-item", {
          'is-active': index === _ctx.selectedIndex,
          'no-under-line': _ctx.tabTags.length == 1,
        }]),
          onClick: ($event: any) => (_ctx.setCurrentTab(index))
        }, _toDisplayString(tab.name) + " " + _toDisplayString(tab.total ? `(${tab.total})` : ""), 11, _hoisted_2))
      }), 128))
    ])
  ]))
}