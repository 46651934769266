
import { defineComponent, ref, reactive, toRefs, onMounted, } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import { RepositoryFactory, ImageFileRepository } from "@/lib/https";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";

export default defineComponent({
  name: "UpdateImageFilePopup",
  components: {
    TextButton,
    Popup,
  },
  setup() {
    const { openNotification } = useNotificationControl();
    const state = reactive({
      baseAvatarUrl: process.env.VUE_APP_API_CLOUD_URL,
      imageFiles: [] as any,
    });

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateImageFile";

    const { getListImageFile, createImageFile, deleteImageFile } =
      RepositoryFactory.getRepository<ImageFileRepository>(ImageFileRepository);

    const onGetImageFile = async () => {
      const response = await getListImageFile();
      if (response) {
        state.imageFiles = response.data;
      }
    };

    const image = ref<HTMLInputElement>();

    const uploadFile = () => {
      const fileInput = image.value;
      fileInput?.click();
    };

    const uploadImage = async (e: any, isDragAndDrop: boolean) => {
      if (isDragAndDrop) {
        onCreateImageFile(e?.dataTransfer.files[0]);
      } else {
        onCreateImageFile(e?.target.files[0]);
      }
    };
    
    const onCreateImageFile = async (image: string) => {
      const formData = new FormData() ;
      formData.append("imageFile", image);
      const response = await createImageFile(formData);
      if (response) {
        state.imageFiles.push(response)
        openNotification();
      }
    };

    const copyToClipboard = (index: number, path: string) => {
      const copyData = '<img class="max-width" border="0" style="display:block; margin-bottom: 30px;" src="'
        + state.baseAvatarUrl + path  + `?${Date.now()}` + '" alt="" width="100%">';
      navigator.clipboard.writeText(copyData);
      state.imageFiles[index].isCopied = true
      setTimeout(() => {
        state.imageFiles[index].isCopied = false;
      }, 1000);
    };

    const onDeleteEmailImage = async (index: number) => {
      const response = await deleteImageFile(state.imageFiles[index].id);
      if (response) {
        state.imageFiles.splice(index, 1)
        openNotification();
      }
    };

    onMounted(onGetImageFile);

    return {
      ...toRefs(state),
      popupKey,
      closePopup,
      image,
      uploadFile,
      uploadImage,
      copyToClipboard,
      onDeleteEmailImage,
    };
  },
});
