import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21a8abae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-files" }
const _hoisted_2 = { class: "section-header big" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "mt-3" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "editor-email" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "waring-container mt-3" }
const _hoisted_10 = { class: "message-warning" }
const _hoisted_11 = { class: "var-prohibit-container mt-2" }
const _hoisted_12 = { class: "var-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_ConfirmSubmitEmailPopup = _resolveComponent("ConfirmSubmitEmailPopup")!
  const _component_UpdateImageFilePopup = _resolveComponent("UpdateImageFilePopup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, null, {
      "top-part": _withCtx(() => [
        _createVNode(_component_BreadScrum, { paths: _ctx.breadscrumLinks }, null, 8, ["paths"])
      ]),
      "right-part": _withCtx(() => [
        _createVNode(_component_FlatButton, {
          class: "save-btn",
          text: _ctx.$t('email.save'),
          onClick: _ctx.onCreateEditEmailTemplate
        }, null, 8, ["text", "onClick"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_TextButton, {
            class: "btn-bottom-right",
            text: _ctx.$t('imageFile.listImageFiles'),
            icon: require('icons/camera.svg'),
            "disabled-icon": require('icons/camera-grey.svg'),
            enabled: true,
            onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openPopup('updateImageFile')))
          }, null, 8, ["text", "icon", "disabled-icon"])
        ])
      ]),
      "left-top-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("email.newEmailType")), 1)
      ]),
      "left-bottom-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("email.emailManagementName")), 1),
          _createVNode(_component_ValidationTextField, {
            "keep-error-space": false,
            value: _ctx.mailTemplateName,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mailTemplateName) = $event)),
            placeholder: _ctx.$t('email.emailManagementName')
          }, null, 8, ["value", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("email.emailMessage")), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Tabs, {
              "onCurrentTab:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentTabIndex = $event)),
              "tab-tags": _ctx.tabTags,
              class: "tabs"
            }, null, 8, ["tab-tags"]),
            (_ctx.currentTabIndex == 0)
              ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                  key: 0,
                  rows: "20",
                  class: "editor-data mt-1",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editorData) = $event))
                }, null, 512)), [
                  [_vModelText, _ctx.editorData]
                ])
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  innerHTML: _ctx.sanitizedEditorData
                }, null, 8, _hoisted_8))
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("email.warningDel")), 1),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templateData.emailVars, (emailVar, i) => {
              return (_openBlock(), _createElementBlock("p", {
                class: "var-prohibit",
                key: i
              }, [
                _createTextVNode(_toDisplayString(emailVar.name) + ": ", 1),
                _createElementVNode("span", _hoisted_12, _toDisplayString(emailVar.description), 1)
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.popupState.confirmSubmitEmail)
      ? (_openBlock(), _createBlock(_component_ConfirmSubmitEmailPopup, {
          key: 0,
          onOnSubmit: _ctx.onSetDefaultEmailTemplate
        }, null, 8, ["onOnSubmit"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.updateImageFile)
      ? (_openBlock(), _createBlock(_component_UpdateImageFilePopup, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}