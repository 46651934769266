import { useRouter } from "vue-router";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import sanitizeHtml from "sanitize-html";

export default function useEmail() {
  const router = useRouter();
  const { popupState } = usePopupControl();

  const handleCopyEmailTemplate = (
    emailTypeId: string,
    emailCopyTemplateId: string
  ) => {
    popupState.showEmailPreview = false;
    router.push({
      name: "EmailAdd",
      params: {
        emailTypeId,
        emailCopyTemplateId,
      },
    });
  };

  sanitizeHtml.defaults.allowedTags = sanitizeHtml.defaults.allowedTags.concat(["img"]);
  const sanitizeHtmlEmail = (editorData: string): string => {
    return sanitizeHtml(editorData, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(["style"]),
      allowedAttributes: {
        "*": ["style", "id", "class", "align"],
        table: ["border", "cellpadding", "cellspacing", "width"],
        img: ["src", "class", "border", "style", "width"],
        a: [ 'href', 'name', 'target' ],
      },
      nonTextTags: ["title"],
    })
  };


  return {
    handleCopyEmailTemplate,
    sanitizeHtmlEmail,
  }
}