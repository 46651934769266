
import { defineComponent } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";

import FlatButton from "@/components/atomics/button/FlatButton.vue";

export default defineComponent({
  name: "ConfirmSubmitEmailPopup",
  components: {
    FlatButton,
    Popup,
  },
  emits: ["onSubmit"],
  setup(_, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "confirmSubmitEmail";

    const confirmSubmit = () => {
      emit("onSubmit");
      close();
    };

    const close = () => {
      closePopup(popupKey);
    }
    return {
      confirmSubmit,
      popupKey,
      close,
    };
  },
});
