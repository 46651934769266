import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ce01c60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tile-position" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.notificationState)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["tile-wrapper", { error: _ctx.notificationType == 'error' }])
            }, [
              (_ctx.notificationType == 'success')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: require('icons/check-mark-green-inverted.svg'),
                    alt: "success",
                    class: "success-check"
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.notificationText), 1)
              ], true),
              _createElementVNode("img", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeNotification && _ctx.closeNotification(...args)), ["stop"])),
                src: require('icons/cancel-white.svg'),
                alt: "icon close",
                class: "cancel"
              }, null, 8, _hoisted_4)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}