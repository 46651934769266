
import { defineComponent, ref, watch } from "vue";
import Notification from "@/components/atomics/Notification.vue";
import { useScroll } from "@vueuse/core";

const __default__ = defineComponent({
  name: "AfterLoginAdminPage",
  props: {
    rightPartWidth: {
      type: String,
      default: "300px",
    },
  },
  components: {
    Notification,
  },
  emits: ["scrolled-to-bottom"],
  setup(_, { emit }) {
    const pageContainer = ref<HTMLElement | null>(null);
    const { arrivedState } = useScroll(pageContainer, {
      offset: { bottom: 10 },
    });

    watch(
      () => arrivedState.bottom,
      (val) => {
        if (val) emit("scrolled-to-bottom");
      }
    );

    return {
      pageContainer,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "cdb7829a": (_ctx.rightPartWidth),
  "bc75f2fc": (_ctx.$slots['right-part'] ? 60: 0)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__