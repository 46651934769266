import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40574ba4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-upload" }
const _hoisted_2 = { class: "image-list" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "image-name" }
const _hoisted_5 = { class: "image-action" }
const _hoisted_6 = ["src", "title", "onClick"]
const _hoisted_7 = ["src", "title", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('imageFile.listImageFiles'),
    "popup-key": _ctx.popupKey
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "image-upload-area",
        onDragleave: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
        onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
        onDrop: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.uploadImage($event, true)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("input", {
            type: "file",
            ref: "image",
            multiple: "",
            accept: "image/png, image/jpeg",
            style: {"display":"none"},
            onChange: _cache[0] || (_cache[0] = (e) => _ctx.uploadImage(e, false))
          }, null, 544),
          _createVNode(_component_TextButton, {
            class: "btn-bottom-right",
            text: _ctx.$t('common.uploadImage'),
            icon: require('icons/camera.svg'),
            "disabled-icon": require('icons/camera-grey.svg'),
            enabled: true,
            onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.uploadFile()))
          }, null, 8, ["text", "icon", "disabled-icon"])
        ])
      ], 32),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageFiles, (image, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "image-list-item",
            key: index
          }, [
            _createElementVNode("img", {
              class: "image-area",
              src: _ctx.baseAvatarUrl + image.filePath
            }, null, 8, _hoisted_3),
            _createElementVNode("p", _hoisted_4, _toDisplayString(image.fileName), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                class: "icon",
                src: image.isCopied ? require('icons/check-mark-grey.svg') : require('icons/content_copy.svg'),
                title: _ctx.$t('imageFile.copyImageTag'),
                onClick: ($event: any) => (_ctx.copyToClipboard(index, image.filePath))
              }, null, 8, _hoisted_6),
              _createElementVNode("img", {
                class: "icon",
                src: require('icons/delete.svg'),
                title: _ctx.$t('imageFile.deleteImage'),
                onClick: ($event: any) => (_ctx.onDeleteEmailImage(index))
              }, null, 8, _hoisted_7)
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}