
import { defineComponent, reactive, toRefs, onMounted, computed, ref } from "vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import ConfirmSubmitEmailPopup from "@/components/popups/email/ConfirmSubmitEmailPopup.vue";
import { RepositoryFactory, EmailRepository } from "@/lib/https";
import { useI18n } from "vue-i18n";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import Tabs from "@/components/atomics/Tabs.vue";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import UpdateImageFilePopup from "@/components/popups/UpdateImageFilePopup.vue";
import useEmail from "@/views/pages/email/email"

export default defineComponent({
  name: "EmailAdd",
  components: {
    BreadScrum,
    ValidationTextField,
    ConfirmSubmitEmailPopup,
    AfterLoginPage,
    FlatButton,
    TextButton,
    Tabs,
    UpdateImageFilePopup,
  },
  props: {
    emailTypeName: {
      type: String,
      default: "",
    },
    emailTypeId: {
      type: String,
      default: "",
    },
    emailTemplateId: {
      type: String,
      default: "",
    },
    emailCopyTemplateId: {
      type: String,
      default: "",
    }
  },
  setup(props) {
    const { t } = useI18n();
    const { popupState, openPopup } = usePopupControl();
    const { openNotification } = useNotificationControl();

    const state = reactive({
      emailTypeName: props.emailTypeName,
      templateData: {
        id: props.emailTemplateId,
        emailTypeId: props.emailTypeId,
        name: "",
        message: "",
        isDefaultTemplate: false,
        emailVars: [] as { name: string; description: string }[],
      },
      editorData: "",
      tabTags: [{ name: t("email.editor") }, { name: t("email.preview") }],
      currentTabIndex: 0,
      mailTemplateName: "",
      breadscrumLinks: [
        { text: t("email.tabtitle"), to: "/email" },
        { text: props.emailTypeName, to: "" },
      ],
    });

    const isEdit = computed(() => (state.templateData.id ? true : false));

    const { getEmailTemplate, createEditEmailTemplate, setDefaultEmailTemplate } =
      RepositoryFactory.getRepository<EmailRepository>(EmailRepository);

    const onCreateEditEmailTemplate = async () => {
      const payload = {
        id: state.templateData.id,
        emailTypeId: state.templateData.emailTypeId,
        name: state.mailTemplateName,
        message: state.editorData,
        isDefaultTemplate: state.templateData.isDefaultTemplate || false,
      };
      const response = await createEditEmailTemplate(payload, isEdit.value);
      if (response) {
        state.templateData = response;
        openNotification();
        if (!state.templateData.isDefaultTemplate) {
          openPopup('confirmSubmitEmail');
        }
      }
    };

    const onGetEmailTemplate = async () => {
      if (isEdit.value) {
        const response = await getEmailTemplate(props.emailTemplateId);
        if (response) {
          state.templateData = response;
          state.mailTemplateName = response.name;
          state.editorData = response.message;
          state.breadscrumLinks[1].text = response.name;
        }
        return;
      } else if (props.emailCopyTemplateId) {
        const response = await getEmailTemplate(props.emailCopyTemplateId);
        if (response) {
          state.templateData.emailTypeId = response.emailTypeId;
          state.mailTemplateName = response.name + t("common.copy");
          state.editorData = response.message;
        }
      } else {
        return;
      }
    };

    onMounted(onGetEmailTemplate);

    const onSetDefaultEmailTemplate = async () => {
      const response = await setDefaultEmailTemplate(state.templateData.id)
      if (response) {
        state.templateData = response;
        openNotification();
      }
    }

    ;
    const sanitizedEditorData = computed(() => useEmail().sanitizeHtmlEmail(state.editorData));

    return {
      ...toRefs(state),
      onCreateEditEmailTemplate,
      onSetDefaultEmailTemplate,
      popupState,
      openPopup,
      sanitizedEditorData,
    };
  },
});
