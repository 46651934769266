
import { defineComponent, ref, PropType } from "vue";

interface TabProps {
  name: string;
  total?: number;
}

export default defineComponent({
  name: "Tabs",
  props: {
    tabTags: {
      type: Array as PropType<Array<TabProps>>,
      default: () => [],
    },
  },
  emits: ["currentTab:value"],
  setup(_, { emit }) {
    const selectedIndex = ref(0);

    const setCurrentTab = (i: number) => {
      selectedIndex.value = i;
      emit("currentTab:value", i);
    };

    return { selectedIndex, setCurrentTab };
  },
});
