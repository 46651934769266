import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50ed63a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "page-container",
  ref: "pageContainer"
}
const _hoisted_2 = { class: "top-part" }
const _hoisted_3 = { class: "left-part pl-3" }
const _hoisted_4 = { class: "left-top-part pt-3" }
const _hoisted_5 = { class: "left-bottom-part" }
const _hoisted_6 = {
  key: 0,
  class: "right-part pl-3 mt-3"
}
const _hoisted_7 = { class: "right-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _renderSlot(_ctx.$slots, "top-part", {}, undefined, true)
    ]),
    _createElementVNode("article", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Notification),
        _renderSlot(_ctx.$slots, "left-top-part", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "left-bottom-part", {}, undefined, true)
      ])
    ]),
    (_ctx.$slots['right-part'])
      ? (_openBlock(), _createElementBlock("aside", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "right-part", {}, undefined, true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}