import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a27b800c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "msg-confirm" }
const _hoisted_2 = { class: "button-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('email.titlePopupConfirmAdd'),
    "popup-key": _ctx.popupKey,
    closeButton: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("email.msgConfirmSubmit")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FlatButton, {
          onOnClick: _ctx.confirmSubmit,
          class: "confirm-btn",
          text: _ctx.$t('email.defaultOn')
        }, null, 8, ["onOnClick", "text"]),
        _createVNode(_component_FlatButton, {
          onOnClick: _ctx.close,
          class: "cancel-btn",
          text: _ctx.$t('email.defaultOff')
        }, null, 8, ["onOnClick", "text"])
      ])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}